<template>
  <div>
    <div class="bestpick">

      <img
        src="http://crenor.speedgabia.com/images/bestPick_01.jpg"
        alt="선생님의 베스트 컨텐츠를 Pick해주세요"
        title="선생님의 베스트 컨텐츠를 Pick해주세요"
        usemap="#image-map"
      >

      <map
        class="pick"
        name="image-map"
      >

        <!-- 이미지 버튼 -->

        <area
          class="content_pick"
          data-num="1"
          alt="엔데믹 시대의 해외여행 필수 백신은?"
          title="엔데믹 시대의 해외여행 필수 백신은?"
          coords="98,566,672,644"
          shape="rect"
          @click="pick(1)"
        >

        <area
          class="content_pick"
          data-num="2"
          alt="심사 상담 에피소트 심사 by 케이스1"
          title="심사 상담 에피소트 심사 by 케이스1"
          coords="99,669,675,742"
          shape="rect"
          @click="pick(2)"
        >

        <area
          class="content_pick"
          data-num="3"
          alt="심사 상담 에피소트 심사 by 케이스2"
          title="심사 상담 에피소트 심사 by 케이스2"
          coords="95,769,673,842"
          shape="rect"
          @click="pick(3)"
        >

        <area
          class="content_pick"
          data-num="4"
          alt="심사 상담 에피소트 심사 by 케이스3"
          title="심사 상담 에피소트 심사 by 케이스3"
          coords="93,870,672,945"
          shape="rect"
          @click="pick(4)"
        >

        <area
          class="content_pick"
          data-num="5"
          alt="수면장애 환자 더 늘어날까?"
          title="수면장애 환자 더 늘어날까?"
          coords="99,1040,671,1114"
          shape="rect"
          @click="pick(5)"
        >

        <area
          class="content_pick"
          data-num="6"
          alt="본인 자각 없는 코골이, 모두가 수면무호흡증?"
          title="본인 자각 없는 코골이, 모두가 수면무호흡증?"
          coords="97,1140,671,1216"
          shape="rect"
          @click="pick(6)"
        >

        <area
          class="content_pick"
          data-num="7"
          alt="심사 상담 에피소드 심사 by 케이스"
          title="심사 상담 에피소드 심사 by 케이스"
          coords="97,1304,671,1381"
          shape="rect"
          @click="pick(7)"
        >

        <area
          class="content_pick"
          data-num="8"
          alt="처방 레시피 1탄, 2형 당뇨병"
          title="처방 레시피 1탄, 2형 당뇨병"
          coords="101,1405,673,1481"
          shape="rect"
          @click="pick(8)"
        >

        <area
          class="content_pick"
          data-num="9"
          alt="심사 상담 에피소드 심사 by 케이스"
          title="심사 상담 에피소드 심사 by 케이스"
          coords="101,1575,669,1650"
          shape="rect"
          @click="pick(9)"
        >

        <area
          class="content_pick"
          data-num="10"
          alt="여성 중심의 탈모 현황과 변화하는 처방 트렌드"
          title="여성 중심의 탈모 현황과 변화하는 처방 트렌드"
          coords="97,1674,672,1752"
          shape="rect"
          @click="pick(10)"
        >

        <!-- 자세히 보러 가기 버튼

'자세히 보러 가기' map의 area 버튼으로 구성, href만 입력하면 됨

-->

        <area
          alt="자세히 보러가기"
          title="자세히 보러가기"
          coords="907,645,673,568"
          shape="rect"
          @click="openContent(1)"
        >

        <area
          alt="자세히 보러가기"
          title="자세히 보러가기"
          coords="676,669,909,742"
          shape="rect"
          @click="openContent(2)"
        >

        <area
          alt="자세히 보러가기"
          title="자세히 보러가기"
          coords="673,768,909,845"
          shape="rect"
          @click="openContent(3)"
        >

        <area
          alt="자세히 보러가기"
          title="자세히 보러가기"
          coords="672,869,909,944"
          shape="rect"
          @click="openContent(4)"
        >

        <area
          alt="자세히 보러가기"
          title="자세히 보러가기"
          coords="672,1037,911,1113"
          shape="rect"
          @click="openContent(5)"
        >

        <area
          alt="자세히 보러가기"
          title="자세히 보러가기"
          coords="672,1140,909,1213"
          shape="rect"
          @click="openContent(6)"
        >

        <area
          alt="자세히 보러가기"
          title="자세히 보러가기"
          coords="673,1304,909,1380"
          shape="rect"
          @click="openContent(7)"
        >

        <area
          alt="자세히 보러가기"
          title="자세히 보러가기"
          coords="673,1404,910,1480"
          shape="rect"
          @click="openContent(8)"
        >

        <area
          alt="자세히 보러가기"
          title="자세히 보러가기"
          coords="672,1574,908,1649"
          shape="rect"
          @click="openContent(9)"
        >

        <area
          alt="자세히 보러가기"
          title="자세히 보러가기"
          coords="673,1674,910,1753"
          shape="rect"
          @click="openContent(10)"
        >

      </map>

      <!--

클릭했을 때, Active되는 이미지 버튼 & '자세히 보러 가기' 버튼

'자세히 보러 가기' map의 area 버튼으로 구성, href만 입력하면 됨

-->

      <div class="pick_active">

        <img
          class="content_pick"
          data-num="1"
          usemap="#image-map2"
          style="top: 0px;"
          src="http://crenor.speedgabia.com/images/pick_01.jpg"
          alt="엔데믹 시대의 해외여행 필수 백신은?"
          title="엔데믹 시대의 해외여행 필수 백신은?"
        >

        <map name="image-map2"><area
          coords="670,22,899,72"
          shape="rect"
          @click="openContent(1)"
        ></map>

        <img
          class="content_pick"
          data-num="2"
          usemap="#image-map3"
          style="top: 102px;"
          src="http://crenor.speedgabia.com/images/pick_02.jpg"
          alt="엔데믹 시대의 해외여행 필수 백신은?"
          title="엔데믹 시대의 해외여행 필수 백신은?"
        >

        <map name="image-map3"><area
          coords="670,22,899,72"
          shape="rect"
          @click="openContent(2)"
        ></map>

        <img
          class="content_pick"
          data-num="3"
          usemap="#image-map4"
          style="top: 197px;"
          src="http://crenor.speedgabia.com/images/pick_03.jpg"
          alt="심사 상담 에피소트 심사 by 케이스2"
          title="심사 상담 에피소트 심사 by 케이스2"
        >

        <map name="image-map4"><area
          coords="670,22,899,72"
          shape="rect"
          @click="openContent(3)"
        ></map>

        <img
          class="content_pick"
          data-num="4"
          usemap="#image-map5"
          style="top: 298px;"
          src="http://crenor.speedgabia.com/images/pick_04.jpg"
          alt="심사 상담 에피소트 심사 by 케이스3"
          title="심사 상담 에피소트 심사 by 케이스3"
        >

        <map name="image-map5"><area
          coords="670,22,899,72"
          shape="rect"
          @click="openContent(4)"
        ></map>

        <img
          class="content_pick"
          data-num="5"
          usemap="#image-map6"
          style="top: 470px;"
          src="http://crenor.speedgabia.com/images/pick_05.jpg"
          alt="수면장애 환자 더 늘어날까?"
          title="수면장애 환자 더 늘어날까?"
        >

        <map name="image-map6"><area
          coords="670,22,899,72"
          shape="rect"
          @click="openContent(5)"
        ></map>

        <img
          class="content_pick"
          data-num="6"
          usemap="#image-map7"
          style="top: 570px;"
          src="http://crenor.speedgabia.com/images/pick_06.jpg"
          alt="본인 자각 없는 코골이, 모두가 수면무호흡증?"
          title="본인 자각 없는 코골이, 모두가 수면무호흡증?"
        >

        <map name="image-map7"><area
          coords="670,22,899,72"
          shape="rect"
          @click="openContent(6)"
        ></map>

        <img
          class="content_pick"
          data-num="7"
          usemap="#image-map8"
          style="top: 737px;"
          src="http://crenor.speedgabia.com/images/pick_07.jpg"
          alt="심사 상담 에피소드 심사 by 케이스"
          title="심사 상담 에피소드 심사 by 케이스"
        >

        <map name="image-map8"><area
          coords="670,22,899,72"
          shape="rect"
          @click="openContent(7)"
        ></map>

        <img
          class="content_pick"
          data-num="8"
          usemap="#image-map9"
          style="top: 835px;"
          src="http://crenor.speedgabia.com/images/pick_08.jpg"
          alt="처방 레시피 1탄, 2형 당뇨병"
          title="처방 레시피 1탄, 2형 당뇨병"
        >

        <map name="image-map9"><area
          coords="670,22,899,72"
          shape="rect"
          @click="openContent(8)"
        ></map>

        <img
          class="content_pick"
          data-num="9"
          usemap="#image-map10"
          style="top: 1005px;"
          src="http://crenor.speedgabia.com/images/pick_09.jpg"
          alt="심사 상담 에피소드 심사 by 케이스"
          title="심사 상담 에피소드 심사 by 케이스"
        >

        <map name="image-map10"><area
          coords="670,22,899,72"
          shape="rect"
          @click="openContent(9)"
        ></map>

        <img
          class="content_pick"
          data-num="10"
          usemap="#image-map11"
          style="top: 1106px;"
          src="http://crenor.speedgabia.com/images/pick_10.jpg"
          alt="여성 중심의 탈모 현황과 변화하는 처방 트렌드"
          title="여성 중심의 탈모 현황과 변화하는 처방 트렌드"
        >

        <map name="image-map11"><area
          coords="670,22,899,72"
          shape="rect"
          @click="openContent(10)"
        ></map>

      </div>

      <img
        src="http://crenor.speedgabia.com/images/bestPick_02.jpg"
        alt="23년 9월 4일(월) ~ 23년 9월 15일(금)까지"
        title="23년 9월 4일(월) ~ 23년 9월 15일(금)까지"
      >

    </div>

    <div class="msg">

      <!-- 메시지 : 이벤트 참여완료 -->

      <button
        v-if="userName && !completeEvent"
        id="submit"
        @click="createEventHistory"
      >
        <img
          id="msg_join"
          src="http://crenor.speedgabia.com/images/bestPick_join.jpg"
        >
      </button>

      <!-- 메시지 : 이미 참여완료하였습니다 -->

      <img
        v-if="userName && completeEvent"
        id="msg_completed"
        src="http://crenor.speedgabia.com/images/bestPick_completed.jpg"
      >

      <!-- 메시지 : 로그인 하러 가기 -->

      <button
        v-if="!userName"
        id="login"
        @click="redirectLogin"
      >
        <img
          id="msg_login"
          src="http://crenor.speedgabia.com/images/bestPick_login.jpg"
        >
      </button>

    </div>

    <popup-medical-view
      ref="popupMedicalView"
      :live-info-ids="contents"
    />

    <alert-dialog :options="alertProps" />
    <confirm-dialog :options="confirmProps" />
  </div>
</template>

<script>
import axios from '@axios'
import { mapGetters } from 'vuex'
import { changeUserPoint } from '@/auth/utils'
import { setStatistics } from '@/common/statistics/service'
import PopupMedicalView from '@/views/apps/medical-trend/PopupMedicalView.vue'

export default {
  components: {
    PopupMedicalView,
  },

  props: {
    eventId: {
      type: Number,
      required: true,
    },
    noticeId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      pickId: null,
      // 실서버 기준 콘텐츠 id 목록
      contents: [10271, 10272, 10285, 10286, 10289, 10295, 10290, 10274, 10291, 10277],

      completeEvent: false,
      isCheckCompleteEvent: false,
      isProcessing: false,
    }
  },

  computed: {
    ...mapGetters({
      userName: 'account/getName',
    }),
  },

  watch: {
    userName() {
      if (this.userName) this.fetchEventComplete()
    },
  },

  created() {
    if (this.userName) this.fetchEventComplete()

    if (process.env.NODE_ENV === 'development') {
      // 콘텐츠ID가 고정되어 있으므로 개발서버에서 테스트 하기 위한 ID값으로 재 설정
      this.contents = [10347, 10348, 10420, 10421, 10422, 10424, 10423, 10352, 10425, 10351]
    }
  },

  methods: {
    getContentId(num) {
      return this.contents[(num - 1)]
    },

    pick(num) {
      const container = document.querySelector(".pick_active")

      // eslint-disable-next-line no-param-reassign
      container.querySelectorAll('img').forEach(x => { x.style.display = "none" })

      const content = container.querySelector(`[data-num='${num}']`)
      content.style.display = "block"

      // 선택한 메디컬트렌드 ID
      this.pickId = this.getContentId(num)
    },

    openContent(num) {
      const contentId = this.getContentId(num)

      this.updateViewCount(contentId)
      this.$refs.popupMedicalView.openMedicalModal(contentId)
    },

    updateViewCount(id) {
      axios.patch(`/fu/medical/UpdateViewCount/${id}`)
        .catch(() => {
        })
    },

    fetchEventComplete() {
      axios.get(`/fu/event/complete/${this.eventId}`)
        .then(rs => {
          if (rs.data) {
            this.completeEvent = true
          } else {
            this.completeEvent = false
          }
          this.isCheckCompleteEvent = true
        })
        .catch(() => {
          this.isCheckCompleteEvent = false
        })
    },

    createEventHistory() {
      if (!this.isCheckCompleteEvent) this.showAlertDialog('이벤트 참여 여부 확인 중에 문제가 발생하였습니다.\n다른 페이지로 이동하였다가 이벤트 메뉴로 재 진입하여 주시기 바랍니다.')

      if (this.userName) {
        if (!this.pickId) this.showAlertDialog('베스트 컨텐츠를 선택해주세요.')
        else if (this.isProcessing === false) {
          this.isProcessing = true

          axios.post('/fu/event/history', {
            EventId: this.eventId,
            ContentId: this.pickId,
          })
            .then(rs => {
              this.showAlertDialog('이벤트 참여 완료 하였습니다.', () => {
                this.completeEvent = true

                setStatistics(121, this.eventId)

                changeUserPoint(rs.data)

                this.isProcessing = false
              })
            })
            .catch(() => {
              this.showAlertDialog('이벤트 참여 중 오류가 발생했습니다.\n잠시 후 다시 시도하여 주시기 바랍니다.')
              this.isProcessing = false
            })
        }
      } else {
        this.redirectLogin()
      }
    },

    redirectLogin() {
      this.$router.push({ name: 'login-main', query: { redirect: 'app-event-main' }, params: { id: this.noticeId } }).catch(() => {})
    },
  }
}
</script>

<style scoped>
img {
  display: block;
  margin: 0 auto;
}

.bestpick, .msg {
  width: 1000px;
  margin: 0 auto;
  position: relative;
}

.msg {
  margin-bottom: 50px;
}

.bestpick .pick_active {
  position: absolute;
  top: 556px;
}

.bestpick .pick_active img {
  display: none;
  position: absolute;
}

area:hover {
  cursor: pointer;
}

button {
  background: none;
  border: none;
}
</style>
