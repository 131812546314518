var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "bestpick" }, [
        _c("img", {
          attrs: {
            src: "http://crenor.speedgabia.com/images/bestPick_01.jpg",
            alt: "선생님의 베스트 컨텐츠를 Pick해주세요",
            title: "선생님의 베스트 컨텐츠를 Pick해주세요",
            usemap: "#image-map",
          },
        }),
        _c("map", { staticClass: "pick", attrs: { name: "image-map" } }, [
          _c("area", {
            staticClass: "content_pick",
            attrs: {
              "data-num": "1",
              alt: "엔데믹 시대의 해외여행 필수 백신은?",
              title: "엔데믹 시대의 해외여행 필수 백신은?",
              coords: "98,566,672,644",
              shape: "rect",
            },
            on: {
              click: function ($event) {
                return _vm.pick(1)
              },
            },
          }),
          _c("area", {
            staticClass: "content_pick",
            attrs: {
              "data-num": "2",
              alt: "심사 상담 에피소트 심사 by 케이스1",
              title: "심사 상담 에피소트 심사 by 케이스1",
              coords: "99,669,675,742",
              shape: "rect",
            },
            on: {
              click: function ($event) {
                return _vm.pick(2)
              },
            },
          }),
          _c("area", {
            staticClass: "content_pick",
            attrs: {
              "data-num": "3",
              alt: "심사 상담 에피소트 심사 by 케이스2",
              title: "심사 상담 에피소트 심사 by 케이스2",
              coords: "95,769,673,842",
              shape: "rect",
            },
            on: {
              click: function ($event) {
                return _vm.pick(3)
              },
            },
          }),
          _c("area", {
            staticClass: "content_pick",
            attrs: {
              "data-num": "4",
              alt: "심사 상담 에피소트 심사 by 케이스3",
              title: "심사 상담 에피소트 심사 by 케이스3",
              coords: "93,870,672,945",
              shape: "rect",
            },
            on: {
              click: function ($event) {
                return _vm.pick(4)
              },
            },
          }),
          _c("area", {
            staticClass: "content_pick",
            attrs: {
              "data-num": "5",
              alt: "수면장애 환자 더 늘어날까?",
              title: "수면장애 환자 더 늘어날까?",
              coords: "99,1040,671,1114",
              shape: "rect",
            },
            on: {
              click: function ($event) {
                return _vm.pick(5)
              },
            },
          }),
          _c("area", {
            staticClass: "content_pick",
            attrs: {
              "data-num": "6",
              alt: "본인 자각 없는 코골이, 모두가 수면무호흡증?",
              title: "본인 자각 없는 코골이, 모두가 수면무호흡증?",
              coords: "97,1140,671,1216",
              shape: "rect",
            },
            on: {
              click: function ($event) {
                return _vm.pick(6)
              },
            },
          }),
          _c("area", {
            staticClass: "content_pick",
            attrs: {
              "data-num": "7",
              alt: "심사 상담 에피소드 심사 by 케이스",
              title: "심사 상담 에피소드 심사 by 케이스",
              coords: "97,1304,671,1381",
              shape: "rect",
            },
            on: {
              click: function ($event) {
                return _vm.pick(7)
              },
            },
          }),
          _c("area", {
            staticClass: "content_pick",
            attrs: {
              "data-num": "8",
              alt: "처방 레시피 1탄, 2형 당뇨병",
              title: "처방 레시피 1탄, 2형 당뇨병",
              coords: "101,1405,673,1481",
              shape: "rect",
            },
            on: {
              click: function ($event) {
                return _vm.pick(8)
              },
            },
          }),
          _c("area", {
            staticClass: "content_pick",
            attrs: {
              "data-num": "9",
              alt: "심사 상담 에피소드 심사 by 케이스",
              title: "심사 상담 에피소드 심사 by 케이스",
              coords: "101,1575,669,1650",
              shape: "rect",
            },
            on: {
              click: function ($event) {
                return _vm.pick(9)
              },
            },
          }),
          _c("area", {
            staticClass: "content_pick",
            attrs: {
              "data-num": "10",
              alt: "여성 중심의 탈모 현황과 변화하는 처방 트렌드",
              title: "여성 중심의 탈모 현황과 변화하는 처방 트렌드",
              coords: "97,1674,672,1752",
              shape: "rect",
            },
            on: {
              click: function ($event) {
                return _vm.pick(10)
              },
            },
          }),
          _c("area", {
            attrs: {
              alt: "자세히 보러가기",
              title: "자세히 보러가기",
              coords: "907,645,673,568",
              shape: "rect",
            },
            on: {
              click: function ($event) {
                return _vm.openContent(1)
              },
            },
          }),
          _c("area", {
            attrs: {
              alt: "자세히 보러가기",
              title: "자세히 보러가기",
              coords: "676,669,909,742",
              shape: "rect",
            },
            on: {
              click: function ($event) {
                return _vm.openContent(2)
              },
            },
          }),
          _c("area", {
            attrs: {
              alt: "자세히 보러가기",
              title: "자세히 보러가기",
              coords: "673,768,909,845",
              shape: "rect",
            },
            on: {
              click: function ($event) {
                return _vm.openContent(3)
              },
            },
          }),
          _c("area", {
            attrs: {
              alt: "자세히 보러가기",
              title: "자세히 보러가기",
              coords: "672,869,909,944",
              shape: "rect",
            },
            on: {
              click: function ($event) {
                return _vm.openContent(4)
              },
            },
          }),
          _c("area", {
            attrs: {
              alt: "자세히 보러가기",
              title: "자세히 보러가기",
              coords: "672,1037,911,1113",
              shape: "rect",
            },
            on: {
              click: function ($event) {
                return _vm.openContent(5)
              },
            },
          }),
          _c("area", {
            attrs: {
              alt: "자세히 보러가기",
              title: "자세히 보러가기",
              coords: "672,1140,909,1213",
              shape: "rect",
            },
            on: {
              click: function ($event) {
                return _vm.openContent(6)
              },
            },
          }),
          _c("area", {
            attrs: {
              alt: "자세히 보러가기",
              title: "자세히 보러가기",
              coords: "673,1304,909,1380",
              shape: "rect",
            },
            on: {
              click: function ($event) {
                return _vm.openContent(7)
              },
            },
          }),
          _c("area", {
            attrs: {
              alt: "자세히 보러가기",
              title: "자세히 보러가기",
              coords: "673,1404,910,1480",
              shape: "rect",
            },
            on: {
              click: function ($event) {
                return _vm.openContent(8)
              },
            },
          }),
          _c("area", {
            attrs: {
              alt: "자세히 보러가기",
              title: "자세히 보러가기",
              coords: "672,1574,908,1649",
              shape: "rect",
            },
            on: {
              click: function ($event) {
                return _vm.openContent(9)
              },
            },
          }),
          _c("area", {
            attrs: {
              alt: "자세히 보러가기",
              title: "자세히 보러가기",
              coords: "673,1674,910,1753",
              shape: "rect",
            },
            on: {
              click: function ($event) {
                return _vm.openContent(10)
              },
            },
          }),
        ]),
        _c("div", { staticClass: "pick_active" }, [
          _c("img", {
            staticClass: "content_pick",
            staticStyle: { top: "0px" },
            attrs: {
              "data-num": "1",
              usemap: "#image-map2",
              src: "http://crenor.speedgabia.com/images/pick_01.jpg",
              alt: "엔데믹 시대의 해외여행 필수 백신은?",
              title: "엔데믹 시대의 해외여행 필수 백신은?",
            },
          }),
          _c("map", { attrs: { name: "image-map2" } }, [
            _c("area", {
              attrs: { coords: "670,22,899,72", shape: "rect" },
              on: {
                click: function ($event) {
                  return _vm.openContent(1)
                },
              },
            }),
          ]),
          _c("img", {
            staticClass: "content_pick",
            staticStyle: { top: "102px" },
            attrs: {
              "data-num": "2",
              usemap: "#image-map3",
              src: "http://crenor.speedgabia.com/images/pick_02.jpg",
              alt: "엔데믹 시대의 해외여행 필수 백신은?",
              title: "엔데믹 시대의 해외여행 필수 백신은?",
            },
          }),
          _c("map", { attrs: { name: "image-map3" } }, [
            _c("area", {
              attrs: { coords: "670,22,899,72", shape: "rect" },
              on: {
                click: function ($event) {
                  return _vm.openContent(2)
                },
              },
            }),
          ]),
          _c("img", {
            staticClass: "content_pick",
            staticStyle: { top: "197px" },
            attrs: {
              "data-num": "3",
              usemap: "#image-map4",
              src: "http://crenor.speedgabia.com/images/pick_03.jpg",
              alt: "심사 상담 에피소트 심사 by 케이스2",
              title: "심사 상담 에피소트 심사 by 케이스2",
            },
          }),
          _c("map", { attrs: { name: "image-map4" } }, [
            _c("area", {
              attrs: { coords: "670,22,899,72", shape: "rect" },
              on: {
                click: function ($event) {
                  return _vm.openContent(3)
                },
              },
            }),
          ]),
          _c("img", {
            staticClass: "content_pick",
            staticStyle: { top: "298px" },
            attrs: {
              "data-num": "4",
              usemap: "#image-map5",
              src: "http://crenor.speedgabia.com/images/pick_04.jpg",
              alt: "심사 상담 에피소트 심사 by 케이스3",
              title: "심사 상담 에피소트 심사 by 케이스3",
            },
          }),
          _c("map", { attrs: { name: "image-map5" } }, [
            _c("area", {
              attrs: { coords: "670,22,899,72", shape: "rect" },
              on: {
                click: function ($event) {
                  return _vm.openContent(4)
                },
              },
            }),
          ]),
          _c("img", {
            staticClass: "content_pick",
            staticStyle: { top: "470px" },
            attrs: {
              "data-num": "5",
              usemap: "#image-map6",
              src: "http://crenor.speedgabia.com/images/pick_05.jpg",
              alt: "수면장애 환자 더 늘어날까?",
              title: "수면장애 환자 더 늘어날까?",
            },
          }),
          _c("map", { attrs: { name: "image-map6" } }, [
            _c("area", {
              attrs: { coords: "670,22,899,72", shape: "rect" },
              on: {
                click: function ($event) {
                  return _vm.openContent(5)
                },
              },
            }),
          ]),
          _c("img", {
            staticClass: "content_pick",
            staticStyle: { top: "570px" },
            attrs: {
              "data-num": "6",
              usemap: "#image-map7",
              src: "http://crenor.speedgabia.com/images/pick_06.jpg",
              alt: "본인 자각 없는 코골이, 모두가 수면무호흡증?",
              title: "본인 자각 없는 코골이, 모두가 수면무호흡증?",
            },
          }),
          _c("map", { attrs: { name: "image-map7" } }, [
            _c("area", {
              attrs: { coords: "670,22,899,72", shape: "rect" },
              on: {
                click: function ($event) {
                  return _vm.openContent(6)
                },
              },
            }),
          ]),
          _c("img", {
            staticClass: "content_pick",
            staticStyle: { top: "737px" },
            attrs: {
              "data-num": "7",
              usemap: "#image-map8",
              src: "http://crenor.speedgabia.com/images/pick_07.jpg",
              alt: "심사 상담 에피소드 심사 by 케이스",
              title: "심사 상담 에피소드 심사 by 케이스",
            },
          }),
          _c("map", { attrs: { name: "image-map8" } }, [
            _c("area", {
              attrs: { coords: "670,22,899,72", shape: "rect" },
              on: {
                click: function ($event) {
                  return _vm.openContent(7)
                },
              },
            }),
          ]),
          _c("img", {
            staticClass: "content_pick",
            staticStyle: { top: "835px" },
            attrs: {
              "data-num": "8",
              usemap: "#image-map9",
              src: "http://crenor.speedgabia.com/images/pick_08.jpg",
              alt: "처방 레시피 1탄, 2형 당뇨병",
              title: "처방 레시피 1탄, 2형 당뇨병",
            },
          }),
          _c("map", { attrs: { name: "image-map9" } }, [
            _c("area", {
              attrs: { coords: "670,22,899,72", shape: "rect" },
              on: {
                click: function ($event) {
                  return _vm.openContent(8)
                },
              },
            }),
          ]),
          _c("img", {
            staticClass: "content_pick",
            staticStyle: { top: "1005px" },
            attrs: {
              "data-num": "9",
              usemap: "#image-map10",
              src: "http://crenor.speedgabia.com/images/pick_09.jpg",
              alt: "심사 상담 에피소드 심사 by 케이스",
              title: "심사 상담 에피소드 심사 by 케이스",
            },
          }),
          _c("map", { attrs: { name: "image-map10" } }, [
            _c("area", {
              attrs: { coords: "670,22,899,72", shape: "rect" },
              on: {
                click: function ($event) {
                  return _vm.openContent(9)
                },
              },
            }),
          ]),
          _c("img", {
            staticClass: "content_pick",
            staticStyle: { top: "1106px" },
            attrs: {
              "data-num": "10",
              usemap: "#image-map11",
              src: "http://crenor.speedgabia.com/images/pick_10.jpg",
              alt: "여성 중심의 탈모 현황과 변화하는 처방 트렌드",
              title: "여성 중심의 탈모 현황과 변화하는 처방 트렌드",
            },
          }),
          _c("map", { attrs: { name: "image-map11" } }, [
            _c("area", {
              attrs: { coords: "670,22,899,72", shape: "rect" },
              on: {
                click: function ($event) {
                  return _vm.openContent(10)
                },
              },
            }),
          ]),
        ]),
        _c("img", {
          attrs: {
            src: "http://crenor.speedgabia.com/images/bestPick_02.jpg",
            alt: "23년 9월 4일(월) ~ 23년 9월 15일(금)까지",
            title: "23년 9월 4일(월) ~ 23년 9월 15일(금)까지",
          },
        }),
      ]),
      _c("div", { staticClass: "msg" }, [
        _vm.userName && !_vm.completeEvent
          ? _c(
              "button",
              {
                attrs: { id: "submit" },
                on: { click: _vm.createEventHistory },
              },
              [
                _c("img", {
                  attrs: {
                    id: "msg_join",
                    src: "http://crenor.speedgabia.com/images/bestPick_join.jpg",
                  },
                }),
              ]
            )
          : _vm._e(),
        _vm.userName && _vm.completeEvent
          ? _c("img", {
              attrs: {
                id: "msg_completed",
                src: "http://crenor.speedgabia.com/images/bestPick_completed.jpg",
              },
            })
          : _vm._e(),
        !_vm.userName
          ? _c(
              "button",
              { attrs: { id: "login" }, on: { click: _vm.redirectLogin } },
              [
                _c("img", {
                  attrs: {
                    id: "msg_login",
                    src: "http://crenor.speedgabia.com/images/bestPick_login.jpg",
                  },
                }),
              ]
            )
          : _vm._e(),
      ]),
      _c("popup-medical-view", {
        ref: "popupMedicalView",
        attrs: { "live-info-ids": _vm.contents },
      }),
      _c("alert-dialog", { attrs: { options: _vm.alertProps } }),
      _c("confirm-dialog", { attrs: { options: _vm.confirmProps } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }